import React from 'react'
import { Link, useInRouterContext } from 'react-router-dom'

import { ComboBoxItem, ComboBoxProps } from '../combo-box'
import { Highlight } from '../highlight'
import { PropsWithAs } from '../types'
import { SearchListItem } from './constants'

interface SearchItemProps extends Pick<ComboBoxProps, 'state'>, Partial<PropsWithAs<React.ElementType>> {
  item: SearchListItem
}

function getItemHrefProps(item: SearchListItem, inRouterContext?: boolean) {
  if (item.href) {
    if (inRouterContext) return { as: Link, to: item.href, reloadDocument: item.reloadDocument, target: item.target }
    else return { as: 'a' as React.ElementType, href: item.href, target: item.target }
  }

  return undefined
}

export function SearchItem({ item, state, ...props }: SearchItemProps) {
  const value = state.useState('value')
  const isInRouterContext = useInRouterContext()

  const itemProps = getItemHrefProps(item, isInRouterContext)

  return (
    <ComboBoxItem
      {...props}
      value={item.value}
      className="h-auto min-h-7 w-full shrink-0 appearance-none py-1 leading-snug"
      contentClass="overflow-hidden px-1 -mx-1"
      {...itemProps}
    >
      <div>
        <Highlight text={item.label} highlight={value} />
      </div>
      {item.subtitle && (
        <div className="text-3xs text-gray-600 dark:text-gray-200">
          <Highlight text={item.subtitle} highlight={value} />
        </div>
      )}
    </ComboBoxItem>
  )
}
