import {
  dedupeIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  thirdPartyErrorFilterIntegration,
} from '@sentry/browser'

export const sentryOptions = {
  dsn: 'https://11de51e753454212a2845e5899a38cae@sentry.io/33153',
  allowUrls: [/finviz\.com/, /www\.finviz\.com/, /elite\.finviz\.com/],
  release: typeof window !== 'undefined' && 'FinvizSentrySettings' in window ? FinvizSentrySettings.release : undefined,
  environment:
    typeof window !== 'undefined' && 'FinvizSentrySettings' in window ? FinvizSentrySettings.environment : undefined,
  sendDefaultPii: true,
  integrations: [
    dedupeIntegration(),
    extraErrorDataIntegration(),
    httpClientIntegration({ failedRequestTargets: [/finviz\.com/] }),
    thirdPartyErrorFilterIntegration({
      filterKeys: ['finviz'], //NOTE: same application keys as specified in Sentry bundle plugin `webpack.config.js` -> `sentryWebpackPlugin`
      behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
    }),
  ],
  /**
   * Ignore if error message matches
   * For ignoring specific files, or errors in specific files use `ignoreStack` below
   */
  ignoreErrors: [
    'AbortError: The operation was aborted.',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'ChunkLoadError: Loading chunk',
    'Error: Loading CSS chunk',
    /^TypeError: Load failed$/,
  ],
  /**
   * Ignore error if a script name in the stack matches any of these
   * For global ignore pattenrs use `ignoreErrors` above
   */
  ignoreStack: [
    // Ignore all quantserve scripts
    'quantcount.com',
    'quantserve.com',
    // Ignore network errors from cmp script
    'investingchannel.com',
    ['cmp2.js', /(AbortError|NetworkError|aborted|network)/i],
  ] satisfies Array<string | [string, RegExp]>,
}
