export enum Instrument {
  Stock = 'stock',
  Forex = 'forex',
  Crypto = 'crypto',
  Futures = 'futures',
  MarketSentiment = 'market_sentiment',
  Group = 'group',
}

export type RecordType = Record<string | number | symbol, unknown>

export type PartialBy<Obj, Keys extends keyof Obj> = Omit<Obj, Keys> & Partial<Pick<Obj, Keys>>

export type NullableNumbers<Obj> = { [K in keyof Obj]: Obj[K] extends number ? number | null : Obj[K] }

export enum DateRangeType {
  d1 = 'd1',
  d5 = 'd5',
  m1 = 'm1',
  m3 = 'm3',
  m6 = 'm6',
  ytd = 'ytd',
  y1 = 'y1',
  y2 = 'y2',
  y5 = 'y5',
  max = 'max',
}

export type SortableValue = string | null | number | Date
export enum SortDirection {
  Ascending = 1,
  Descending = -1,
}

export enum FeatureFlag {
  RedesignedPages = 'redesign',
  PortfolioRedesign = 'redesignpfo',
  OptionsGreeks = 'greeks',
  TrafficPage = 'trafficPage',
  ScreenerAddFilter = 'screeneraddfilter',
}

export interface ApiPaginatedResponse<T> {
  items: T
  page: number
  pageSize: number
  totalItemsCount: number
  totalPages: number
}
