import classnames from 'classnames'
import * as React from 'react'

import { Icon, IconNameType, isValidIconName } from './icon'
import { PropsWithAs } from './types'

export const BUTTON_DEFAULT_ELEMENT = 'button'

export const BUTTON_BASE_CLASS =
  'group/button flex shrink-0 items-center justify-center font-medium whitespace-nowrap focus:outline-none'
export const BUTTON_SQUARE_BASE_CLASS = 'shrink-0 justify-center'
export const BUTTON_FOCUS_STYLES = 'focus-visible:ring-2 ring-gray-200 dark:ring-gray-500'

export enum ButtonSize {
  none = '', // Do not set fixed dimensions
  xxsmall = 'text-info h-4 min-w-4', // 16px height
  xsmall = 'text-2xs h-5 min-w-5', // 20px height
  small = 'text-2xs h-6 min-w-6', // 24px height
  regular = 'text-2xs h-7 min-w-7', // 28px height
  medium = 'text-sm h-8 min-w-8', // 32px height
  large = 'text-sm h-9 min-w-9', // 36px height
  xlarge = 'text-sm h-11 min-w-9', // 44px height
}

// Must have the same keys as ButtonSize
export enum ButtonPadding {
  none = '',
  xxsmall = 'px-0.5', // 2px padding
  xsmall = 'px-1', // 4px padding
  small = 'px-1.5', // 6px padding
  regular = 'px-2', // 8px padding
  medium = 'px-2.5', // 10px padding
  large = 'px-3', // 12px padding
  xlarge = 'px-3.5', // 14px padding
}

export enum ButtonAlign {
  none = '',
  left = 'text-left',
  center = 'text-center',
  right = 'text-right',
}

export type ButtonSizeType = keyof typeof ButtonSize

export enum ButtonSquareSize {
  none = '', // Do not set fixed dimensions
  xxsmall = 'h-4 w-4', // 16x16px
  xsmall = 'h-5 w-5', // 20x20px
  small = 'h-6 w-6', // 24x24px
  regular = 'h-7 w-7', // 28x28px
  medium = 'h-8 w-8', // 32x32px
  large = 'h-9 w-9', // 36x36px
  xlarge = 'h-11 w-11', // 44x44px
}

export enum ButtonRounding {
  none = '',
  small = 'rounded', // 4px
  regular = 'rounded-md', // 6px
  full = 'rounded-full', // 9999px
}

export type ButtonRoundingType = keyof typeof ButtonRounding

export enum ButtonTheme {
  transparent, // Only basic shape is rendered
  light,
  lightTransparent,
  dark,
  blue,
  violet,
  blueHover,
  red,
  headerItem,
  menuItem,
  menuItemTransparent,
  menuItemDark,
  opacity,
  outline,
  chipTransparent,
  chip,
  chipOutline,
  link,
  linkText,
  tab,
}

export type ButtonThemeType = keyof typeof ButtonTheme

/**
 * Get button theme
 */
export function getButtonTheme({
  theme,
  active = false,
  size = 'regular',
}: {
  theme: ButtonTheme
  active?: boolean
  size?: ButtonSizeType
}) {
  switch (theme) {
    case ButtonTheme.light: {
      return {
        button: classnames(
          'border border-transparent active:bg-gray-200/90 disabled:bg-gray-200/30 disabled:text-gray-400 disabled:hover:bg-gray-200/30 disabled:hover:text-gray-400', // Light
          'dark:active:bg-gray-500/90 dark:disabled:bg-gray-500/30 dark:disabled:text-gray-300 dark:disabled:hover:bg-gray-500/30 dark:disabled:hover:text-gray-300', // Dark
          {
            'bg-gray-200/30 hover:bg-gray-200/50 dark:bg-gray-500/30 dark:hover:bg-gray-500/50': !active,
            'bg-gray-200/90 dark:bg-gray-500/90': active,
          }
        ),
      }
    }
    case ButtonTheme.lightTransparent: {
      return {
        button: classnames(
          'border border-transparent active:bg-gray-200/90 disabled:bg-transparent disabled:text-gray-400 disabled:hover:bg-transparent disabled:hover:text-gray-400', // Light
          'dark:active:bg-gray-500/90 dark:disabled:bg-gray-500/30 dark:disabled:text-gray-300 dark:disabled:hover:bg-gray-500/30 dark:disabled:hover:text-gray-300', // Dark
          {
            'bg-transparent hover:bg-gray-200/50 dark:bg-gray-500/30 dark:hover:bg-gray-500/50': !active,
            'bg-gray-200/90 dark:bg-gray-500/90': active,
          }
        ),
      }
    }
    case ButtonTheme.dark: {
      return {
        button: classnames(
          'border border-transparent text-gray-50 active:bg-gray-500/90 disabled:bg-gray-500/30 disabled:text-gray-300 disabled:hover:bg-gray-500/30 disabled:hover:text-gray-300',
          {
            'bg-gray-500/30 hover:bg-gray-500/50': !active,
            'bg-gray-500/90': active,
          }
        ),
      }
    }
    case ButtonTheme.blue: {
      return {
        button: classnames(
          'border border-transparent text-white hover:text-white active:bg-blue-600 disabled:bg-blue-200 dark:disabled:text-gray-300 disabled:hover:bg-blue-200 dark:disabled:hover:text-gray-300',
          {
            'bg-blue-400 hover:bg-blue-500': !active,
            'bg-blue-600': active,
          }
        ),
      }
    }
    case ButtonTheme.violet: {
      return {
        button: classnames(
          'text-white hover:text-white focus:text-white disabled:bg-violet-200 dark:disabled:text-gray-300 disabled:hover:bg-violet-200 dark:disabled:hover:text-gray-300',
          {
            'bg-violet-500 hover:bg-violet-600': !active,
            'bg-violet-700': active,
          }
        ),
      }
    }
    case ButtonTheme.blueHover: {
      return {
        button: classnames(
          'border border-transparent active:bg-blue-600 disabled:bg-blue-200 disabled:text-gray-400 dark:disabled:text-gray-300 disabled:hover:bg-blue-200 disabled:hover:text-gray-400 dark:disabled:hover:text-gray-300',
          {
            'text-blue-600 hover:bg-blue-400 hover:text-white dark:text-blue-500 dark:hover:text-white': !active,
            'bg-blue-400 text-white': active,
          }
        ),
      }
    }
    case ButtonTheme.red: {
      return {
        button: classnames(
          'border border-transparent text-white active:bg-red-600 disabled:bg-red-200 disabled:text-gray-300 disabled:hover:bg-red-200 disabled:hover:text-gray-300',
          {
            'bg-red-400 hover:bg-red-500': !active,
            'bg-red-600': active,
          }
        ),
      }
    }
    case ButtonTheme.headerItem: {
      return {
        button: classnames(
          'border border-transparent bg-gray-800 text-gray-300 hover:bg-gray-900 hover:text-white disabled:hover:text-gray-300 disabled:bg-gray-700 disabled:hover:bg-gray-700' // Base
        ),
        icon: 'text-white',
      }
    }
    case ButtonTheme.menuItem: {
      return {
        button: classnames(
          'border border-transparent dark:disabled:hover:bg-transparent disabled:hover:bg-transparent',
          {
            'hover:bg-gray-200/30 disabled:text-gray-400 disabled:hover:text-gray-400 dark:hover:bg-gray-500/30':
              !active,
            'bg-gray-200/30 dark:bg-gray-500/30': active,
          }
        ),
      }
    }
    case ButtonTheme.menuItemTransparent: {
      return {
        button: 'border border-transparent disabled:text-gray-400',
      }
    }
    case ButtonTheme.menuItemDark: {
      return {
        button: classnames('border border-transparent text-white dark:disabled:hover:bg-transparent', {
          'hover:bg-gray-700': !active,
          'bg-gray-700': active,
        }),
      }
    }
    case ButtonTheme.opacity:
      return {
        button: classnames(
          'border border-transparent disabled:opacity-50 disabled:hover:opacity-50 dark:text-gray-400',
          {
            'opacity-70 hover:opacity-100': !active,
          }
        ),
      }
    case ButtonTheme.outline:
      return {
        button: classnames(
          // Base styles
          'bg-primary border disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-500 disabled:hover:border-gray-100 disabled:hover:bg-gray-50 disabled:hover:text-gray-500',
          // Dark styles
          'dark:bg-gray-800 dark:text-gray-50 dark:disabled:border-transparent dark:disabled:bg-gray-700 dark:disabled:text-gray-400 dark:disabled:hover:border-transparent dark:disabled:hover:bg-gray-700 dark:disabled:hover:text-gray-400',
          {
            'border-blue-400 dark:border-blue-400': active,
            'border-gray-100 hover:border-gray-300 dark:hover:border-gray-400 dark:border-gray-600': !active,
          }
        ),
      }
    case ButtonTheme.chip:
      return {
        button: classnames('border dark:disabled:hover:bg-gray-700 disabled:hover:bg-gray-50', {
          'border-transparent bg-gray-200/30 hover:bg-gray-200/30 dark:bg-gray-700 dark:hover:bg-gray-500/30': !active,
          'border-blue-400 bg-blue-50 text-gray-900 dark:border-blue-400 dark:bg-gray-500/30 dark:text-white': active,
        }),
      }
    case ButtonTheme.chipOutline:
      return {
        button: classnames(
          // Base styles
          'border text-gray-600 disabled:text-gray-400 disabled:hover:border-gray-200 disabled:hover:bg-primary disabled:hover:text-gray-400',
          // Dark styles
          'dark:text-gray-200 dark:disabled:text-gray-400 dark:disabled:hover:text-gray-400 dark:disabled:hover:border-gray-600 dark:disabled:hover:bg-primary',
          {
            'bg-primary border-primary hover:text-default hover:border-gray-300 hover:bg-gray-50 dark:hover:text-default dark:hover:bg-gray-900 dark:hover:border-gray-400':
              !active,
            'text-default border-blue-400 bg-blue-50 dark:bg-blue-700 dark:text-white': active,
          }
        ),
      }
    case ButtonTheme.chipTransparent:
      return {
        button: classnames(
          'border dark:disabled:hover:bg-transparent disabled:hover:bg-transparent dark:disabled:hover:text-gray-400 disabled:hover:text-gray-500', // Base styles
          {
            'border-transparent text-gray-500 hover:bg-gray-200/30 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-500/30 dark:hover:text-gray-200':
              !active,
            'border-blue-400 bg-blue-50 text-gray-900 dark:bg-gray-500/30 dark:text-white': active,
          }
        ),
      }
    case ButtonTheme.link: {
      return {
        button: classnames(
          'border border-transparent disabled:text-blue-500/50 disabled:hover:text-blue-500/50', // Light
          'dark:disabled:text-blue-300/50 dark:disabled:hover:text-blue-300/50', // Dark
          {
            'text-blue-500 hover:text-blue-600 dark:text-blue-300 dark:hover:text-blue-200': !active,
            'bg-blue-50 text-blue-600 dark:bg-blue-700 dark:text-white': active,
          }
        ),
      }
    }
    case ButtonTheme.linkText: {
      return {
        button:
          'text-inherit font-normal hover:text-blue-600 dark:hover:text-blue-200 no-underline hover:underline disabled:opacity-50 disabled:hover:opacity-50',
      }
    }
    case ButtonTheme.tab: {
      return {
        button: classnames('border-b', {
          'border-b-gray-100 text-default dark:border-b-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:border-b-gray-600':
            !active,
          'text-blue-500 border-b-blue-400 shadow-blue-400 dark:text-blue-300': active,
          'shadow-[inset_0_-1px_0]': active && size !== 'xlarge',
          'shadow-[inset_0_-3px_0]': active && size === 'xlarge',
        }),
      }
    }
    default:
      return {}
  }
}

/**
 * Export Button html props type so we don’t always have to omit
 */
export type ButtonHTMLProps = Omit<
  React.HTMLProps<HTMLButtonElement>,
  'ref' | 'type' | 'size' | 'content' | 'align' | 'as'
>

export interface ButtonProps {
  /**
   * Visual appearance of the button
   */
  theme?: ButtonThemeType

  /**
   * Sets if button is square or normal.
   *
   * @default 'regular'
   */
  appearance?: 'regular' | 'square'

  /**
   * Size of the button
   *
   * @default "regular"
   */
  size?: ButtonSizeType

  /**
   * Override align of the text for a button
   *
   * @default center, left when `leftContent` or `rightContent` are defined
   */
  align?: keyof typeof ButtonAlign

  /**
   * Override button padding
   *
   * @default undefined
   */
  padding?: keyof typeof ButtonPadding

  /**
   * Render button in an active state (darker background, etc.)
   */
  active?: boolean

  /**
   * Sets if the button has focus ring
   *
   * @default true
   */
  focusRing?: boolean

  /**
   * Sets rounded corners for the button
   *
   * @default regular
   */
  rounding?: ButtonRoundingType

  /**
   * Class for the content wrapper
   */
  contentClass?: string

  /**
   * Name of left icon
   */
  leftContent?: JSX.Element | IconNameType

  /**
   * Name of right icon
   */
  rightContent?: JSX.Element | IconNameType
}

export type ButtonComponentProps<TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT> =
  PropsWithAs<TagType> & ButtonProps

function ButtonComponent<TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT>(
  {
    as: asComponent,
    theme = 'light',
    size = 'regular',
    rounding = 'regular',
    focusRing = true,
    appearance = 'regular',
    align,
    padding = size, // same as size
    active,
    contentClass,
    leftContent,
    rightContent,
    className,
    children,
    ...props
  }: ButtonComponentProps<TagType>,
  ref: React.ForwardedRef<HTMLElement>
) {
  const isSquare = appearance === 'square'
  const buttonTheme = React.useMemo(
    () => getButtonTheme({ theme: ButtonTheme[theme], active, size }),
    [theme, active, size]
  )
  const buttonPadding = React.useMemo(() => (isSquare ? null : ButtonPadding[padding]), [isSquare, padding])
  const buttonSize = isSquare ? ButtonSquareSize[size] : ButtonSize[size]
  const buttonRounding = ButtonRounding[rounding]
  const hasIcons = leftContent || rightContent

  return React.createElement(
    asComponent ?? BUTTON_DEFAULT_ELEMENT,
    {
      ...props,
      ref,
      className: classnames(
        BUTTON_BASE_CLASS,
        buttonTheme.button,
        buttonPadding,
        buttonSize,
        buttonRounding,
        {
          [BUTTON_SQUARE_BASE_CLASS]: isSquare,
          [BUTTON_FOCUS_STYLES]: focusRing,
        },
        className
      ),
    },
    <>
      {leftContent && (
        <div className="flex w-3 items-center justify-center">
          {isValidIconName(leftContent) ? <Icon name={leftContent} className={buttonTheme.icon} /> : leftContent}
        </div>
      )}
      {children && !isSquare ? (
        <div
          className={classnames(contentClass, 'grow', (align ?? hasIcons) ? ButtonAlign.left : ButtonAlign.center, {
            'ml-0.5': leftContent && ButtonSize.xxsmall === ButtonSize[size],
            'ml-1': leftContent && ButtonSize.xsmall === ButtonSize[size],
            'ml-1.5': leftContent && [ButtonSize.none, ButtonSize.small, ButtonSize.regular].includes(ButtonSize[size]),
            'ml-2': leftContent && [ButtonSize.medium, ButtonSize.large, ButtonSize.xlarge].includes(ButtonSize[size]),
            'mr-0.5': rightContent && ButtonSize.xxsmall === ButtonSize[size],
            'mr-1': rightContent && ButtonSize.xsmall === ButtonSize[size],
            'mr-1.5':
              rightContent && [ButtonSize.none, ButtonSize.small, ButtonSize.regular].includes(ButtonSize[size]),
            'mr-2': rightContent && [ButtonSize.medium, ButtonSize.large, ButtonSize.xlarge].includes(ButtonSize[size]),
          })}
        >
          {children}
        </div>
      ) : (
        children
      )}
      {rightContent && (
        <div className="flex w-3 items-center justify-center">
          {isValidIconName(rightContent) ? <Icon name={rightContent} className={buttonTheme.icon} /> : rightContent}
        </div>
      )}
    </>
  )
}

/**
 * We need to forward ref to the function, but typescript looses the type of the
 * props param so we need to typecast to get suggestions to work
 */
export const Button = React.forwardRef(ButtonComponent) as <
  TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT,
>(
  props: PropsWithAs<TagType> & ButtonProps & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof ButtonComponent>
